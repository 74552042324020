import { Container, Col, Row } from "react-bootstrap";
import logo from "../assets/img/cazlogo.JPG";
import React from 'react';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Footer = () => {
    return(
        <footer className="footer">
  <Container>
    <Row className="footer-top">
      {/* Left Section: Logo and Social Media */}
      <Col xs={12} md={4} className="footer-section text-center text-md-left">
        <a href="#home">
          <img className="footer-logo" src={logo} alt="Logo" />
        </a>
        <div className="social-icon">
          <a
            href="https://www.instagram.com/cccoaching_ukltd/?igsh=aXkzOXkwcjdjM2Z3"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={navIcon3} alt="Instagram" />
          </a>
          <a
            href="https://www.facebook.com/ccowp/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={navIcon2} alt="Facebook" />
          </a>
          <a
            href="https://www.linkedin.com/in/carolyn-cowperthwaite-61747b141/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={navIcon1} alt="LinkedIn" />
          </a>
        </div>
      </Col>

      {/* Middle Section: Quick Links */}
      <Col xs={12} md={4} className="footer-section text-center">
        <h5>Quick Links</h5>
        <ul className="footer-links">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/contact">Contact Us</a>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
        </ul>
      </Col>

      {/* Right Section: Contact Info */}
      <Col xs={12} md={4} className="footer-section text-center text-md-right">
        <h5>Contact</h5>
        <p>
          <strong>Email:</strong> cccoaching1@outlook.com
        </p>
        <p>
          <strong>Phone:</strong> +44 7958 524020
        </p>
        <p>
          <strong>Address:</strong> 20 Wenlock Road, London N1 7GU
        </p>
      </Col>
    </Row>

    {/* Footer Bottom */}
    <Row className="footer-bottom">
      <Col sm={12} className="text-center">
        <p>© 2025 C.C Coaching U.K Ltd. All Rights Reserved.</p>
      </Col>
    </Row>
  </Container>
</footer>


    )
}