import { useState, useRef } from "react";
import { Col, Row, Container } from "react-bootstrap";
import React from 'react';
import emailjs from '@emailjs/browser';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'; 

export const Contact = () => {
    const formInitialDetails = {
        Name: '',
        email: '',
        phone: '',
        message: ''
    };
    const form = useRef();
    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});
    const [errors, setErrors] = useState({});

    const containerStyle = {
        width: '100%',
        height: '400px',
        marginTop: '20px' 
    };

    const center = {
        lat: 51.530572, 
        lng: -0.093491
    };

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        });

        setErrors({
            ...errors,
            [category]: ''
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formDetails.Name) newErrors.Name = "First name is required";
        if (!formDetails.email) newErrors.email = "Email is required";
        if (!formDetails.phone) newErrors.phone = "Phone number is required";
        if (!formDetails.message) newErrors.message = "Message is required";
        return newErrors;
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setButtonText("Sending...");
        emailjs
            .sendForm(
                'service_wdh62ak',
                'template_siwwsfd',
                form.current,
                'eCtzR2DBxphGEa937'
            )
            .then(
                () => {
                    setButtonText("Sent");
                    setStatus({ success: true, message: "Message sent successfully!" });
                    setFormDetails(formInitialDetails);
                    setTimeout(() => setButtonText("Send"), 2000);
                },
                (error) => {
                    setButtonText("Send");
                    setStatus({ success: false, message: "Failed to send message." });
                    console.error("FAILED...", error.text);
                }
            );
    };

    return (
        <>
            <section className="contact" id="connect">
                <Container classname="contactboxes">
                    <h2>Contact us:</h2>
                    <form ref={form} onSubmit={sendEmail}>
                        <Row className="mb-4">
                            {/* Name Input */}
                            <Col sm={12} md={4} className="contact-item contact1">
                                <input
                                    type="text"
                                    name="Name"
                                    value={formDetails.Name}
                                    placeholder="Name"
                                    onChange={(e) => onFormUpdate('Name', e.target.value)}
                                />
                                {errors.Name && <p className="error-text">{errors.Name}</p>}
                            </Col>

                            {/* Email Input */}
                            <Col sm={12} md={4} className="contact-item contact2">
                                <input
                                    type="email"
                                    name="email"
                                    value={formDetails.email}
                                    placeholder="Email"
                                    onChange={(e) => onFormUpdate('email', e.target.value)}
                                />
                                {errors.email && <p className="error-text">{errors.email}</p>}
                            </Col>

                            {/* Phone Input */}
                            <Col sm={12} md={4} className="contact-item contact3">
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formDetails.phone}
                                    placeholder="Phone"
                                    onChange={(e) => onFormUpdate('phone', e.target.value)}
                                />
                                {errors.phone && <p className="error-text">{errors.phone}</p>}
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            {/* Message Textarea */}
                            <Col sm={12} md={12} className="textarea-col contact-item contact4">
                                <textarea
                                    rows="6"
                                    name="message"
                                    value={formDetails.message}
                                    placeholder="Message"
                                    onChange={(e) => onFormUpdate('message', e.target.value)}
                                />
                                
                            </Col>
                            {errors.message && <p className="error-text">{errors.message}</p>}
                        </Row>

                        <Row className="text-center contact-item contact5">
                            {/* Submit Button */}
                            <Col sm={12}>
                                <button type="submit">
                                    <span>{buttonText}</span>
                                </button>
                                {status.message && (
                                    <p className={status.success ? "success-text" : "error-text"}>
                                        {status.message}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </form>
                </Container>
            </section>

            <section className="map">
                <Container>
                    <LoadScript googleMapsApiKey="AIzaSyA9aOQvBLgBW462vXnnX1FvtQVzxbWtLQ0">
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={12}
                        >
                            <Marker position={center} />
                        </GoogleMap>
                    </LoadScript>
                </Container>
            </section>
        </>
    );
};